const booleanSwitch = (state, array, key, value) => {
  if (!state && state.lenght === 0) {
    return null;
  }

  const element = state[array].find(e => e.value === value);

  switch (element[key]) {
    case true:
      element[key] = false;
      break;
    default:
      element[key] = true;
      break;
  }

  return element;
};

export default booleanSwitch;
