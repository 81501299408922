import { IS_NEW_PDF_LINK_ENABLED } from '../featureFlags';

const LINKS = {
  PORTLAND: 'https://portland.forma.ru',
  LK: 'https://lk.forma.ru',
  ADMIN_PDF: IS_NEW_PDF_LINK_ENABLED ? process.env.REACT_APP_ADMIN_PDF_GEN : process.env.REACT_APP_ADMIN_FORMA,
  YANDEX_CLOUD: process.env.REACT_APP_YANDEX_CLOUD,
};

export default LINKS;
