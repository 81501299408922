import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import constants from '../constants/API';

//Используется в extraReducers
export const fetchGallerySlider = createAsyncThunk(
  'gallerySlice/fetchGallerySlider1',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${constants.urlUniversalApi}/gallery`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

//Используется в extraReducers
export const fetchGalleryAbout = createAsyncThunk('gallerySlice/fetchGalleryAbout', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${constants.urlUniversalApi}/slider/aboutpage`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const gallerySlice = createSlice({
  name: 'gallerySlice',
  initialState: {
    width: typeof window === 'object' ? window.innerWidth : null,
    photos: [],
    aboutPhotos: [],
    activePhotoPack: null,
    activeSlide: 0,
    gallerySliderError: false,
  },
  reducers: {
    changeActivePhotoPack(state, action) {
      if (action.payload === 'about') {
        state.activePhotoPack = state.aboutPhotos[0];
      } else {
        state.activePhotoPack = state.photos.find(item => item.title.includes(action.payload));
      }
    },
    changeActiveSlide(state, action) {
      state.activeSlide = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGallerySlider.fulfilled, (state, action) => {
        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.photos.push(...action.payload);
        }
      })
      .addCase(fetchGallerySlider.rejected, state => {
        state.gallerySliderError = true;
      })
      .addCase(fetchGalleryAbout.fulfilled, (state, action) => {
        if (action.payload) {
          state.aboutPhotos.push(action.payload);
        }
      });
  },
});

export default gallerySlice.reducer;

export const { changeActivePhotoPack, changeActiveSlide } = gallerySlice.actions;
