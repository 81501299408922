import 'swiper/css';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import './App.scss';
import useAxios from 'axios-hooks';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import SwiperCore from 'swiper';
import { FreeMode, Pagination } from 'swiper/modules';
import GallerySlider from './components/gallery/gallery-slider/GallerySlider';
import HelmetSample from './components/helmet-sample/HelmetSample';
import MainLoader from './components/main-loader/MainLoader';
import RotateCaution from './components/rotate-caution/RotateCaution';
import constants from './constants/API';
import LINKS from './constants/links';
import ROUTES from './constants/routes';
import { NEW_MAIN_PAGE, PARKING_FEATURE_ENABLED, SITEMAP_FEATURE_ENABLED } from './featureFlags';
import ServerErrorPage from './pages/server-error-page/ServerErrorPage';
import { refreshFavoriteList } from './state/favoriteSlice';
import { fetchGeneralSettings, handleNewUser } from './state/mainSlice';
import { getNewUser } from './state/selectors';
import { disableBodyScroll, enableBodyScroll } from './tools/_body-scroll-lock';
import ScrollToTop from './tools/_scroll-to-top';
import { getCookie } from './utilits/getCookie';

const BookingPage = lazy(() => import('./pages/booking-page/BookingPage'));
const MainPage = lazy(() => import('./pages/main-page/MainPage'));
const PolicyPage = lazy(() => import('./pages/policy-page/PolicyPage'));
const CatalogPage = lazy(() => import('./pages/catalog-page/CatalogPage'));
const FlatPage = lazy(() => import('./pages/flat-page/FlatPage'));
const ContactsPage = lazy(() => import('./pages/contacts-page/ContactsPage'));
const AboutPage = lazy(() => import('./pages/about-page/AboutPage'));
const SeafrontPage = lazy(() => import('./pages/seafront-page/SeafrontPage'));
const NewsPage = lazy(() => import('./pages/news-page/NewsPage'));
const NewsOnePage = lazy(() => import('./pages/news-one-page/NewsOnePage'));
const BurgerMenu = lazy(() => import('./components/header/burger-menu/BurgerMenu'));
const ModalForm = lazy(() => import('./components/modal-form/ModalForm'));
const FavoritesPage = lazy(() => import('./pages/favorites-page/FavoritesPage'));
const DocumentsPage = lazy(() => import('./pages/documents-page/DocumentsPage'));
const ErrorPage = lazy(() => import('./pages/error-page/ErrorPage'));
const GalleryPage = lazy(() => import('./pages/gallery-page/GalleryPage'));
const CommercialPage = lazy(() => import('./pages/commercial-page/CommercialPage'));
const ArchitecturePage = lazy(() => import('./pages/architecture-page/ArchitecturePage'));
const TermsPage = lazy(() => import('./pages/terms-page/TermsPage'));
const InfrastructurePage = lazy(() => import('./pages/infrastructure-page/InfrastructurePage'));
const TeamPage = lazy(() => import('./pages/team-page/TeamPage'));
const LandscapingPage = lazy(() => import('./pages/landscaping-page/LandscapingPage'));
const VisualPage = lazy(() => import('./pages/visual-page/VisualPage'));
const HousePage = lazy(() => import('./pages/house-page/HousePage'));
const FloorPage = lazy(() => import('./pages/floor-page/FloorPage'));
const ProgressPage = lazy(() => import('./pages/progress-page/ProgressPage'));
const ProgressOnePage = lazy(() => import('./pages/progress-one-page/ProgressOnePage'));
const CookiePopup = lazy(() => import('./components/cookie-popup/CookiePopup'));
const CashbackConditionsPage = lazy(() => import('./pages/cashback-conditions-page/CashbackConditionsPage'));
const CashbackPage = lazy(() => import('./pages/cashback-page/CashbackPage'));
const ParkingPage = lazy(() => import('./pages/parking-page/ParkingPage'));
const SiteMapPage = lazy(() => import('./pages/sitemap-page/SiteMapPage'));

// install Swiper modules
SwiperCore.use([Pagination, FreeMode]);
function App() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFont, setIsLoadingFont] = useState(false);
  const [isReadyComplete, setReadyComplete] = useState(false);
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const { pathname } = useLocation();

  const savedFavorites = JSON.parse(localStorage.getItem('favoriteList'));
  const [{ data: checkedFavoriteFlats }] = useAxios({
    url:
      savedFavorites &&
      constants.url.replace('/?blockId', `/check?ids=${savedFavorites.map(flat => flat.id).join('|')}&blockId`),
  });

  const { isLandscape } = useMobileOrientation();
  const isANewUser = useSelector(getNewUser);

  if (isLandscape && isMobileOnly) {
    disableBodyScroll();
  } else {
    enableBodyScroll();
  }

  useEffect(() => {
    setReadyComplete(false);
    setIsLoadingFont(false);
    setIsLoading(false);
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchGeneralSettings());
  }, []);

  //Отслеживание фактической загрузки сайта
  useLayoutEffect(() => {
    if (isReadyComplete === false) {
      document.addEventListener('readystatechange', event => {
        if (event.target.readyState === 'interactive') {
        } else if (event.target.readyState === 'complete') {
          setReadyComplete(true);
        }
      });
    }

    if (isLoadingFont === false) {
      document.fonts.ready.then(() => {
        setIsLoadingFont(true);
      });
    }

    if (isReadyComplete && isLoadingFont) {
      setTimeout(() => {
        typeof window.is_server !== typeof undefined ? setIsLoading(false) : setIsLoading(true);
      }, 300);
    }

    setTimeout(() => {
      if (isLoading === false) {
        setIsLoading(true);
      }
    }, 3500);
  }, [isReadyComplete, isLoadingFont, isLoading]);

  useEffect(() => {
    //Проверка, являются ли квартиры, сохраненные в Избранное, до сих пор доступными для бронирования
    if (savedFavorites && savedFavorites.length > 0 && checkedFavoriteFlats) {
      const freeFavoritesFlats = [];
      Object.keys(checkedFavoriteFlats).forEach(key => {
        if (checkedFavoriteFlats[key]) {
          const flat = savedFavorites.find(flat => flat.id === Number(key));
          if (flat) {
            freeFavoritesFlats.push(flat);
          }
        }
      });
      dispatch(refreshFavoriteList(freeFavoritesFlats));
    }
  }, [checkedFavoriteFlats]);

  const { scroll } = useLocomotiveScroll();
  // Register scroll trigger plugin
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    ScrollTrigger.addEventListener('refresh', () => scroll?.update());
    ScrollTrigger.refresh();
  }, [scroll]);

  useEffect(() => {
    if (!getCookie('portland_user')) {
      setTimeout(() => setShowCookiePopup(true), 1000);
    } else {
      dispatch(handleNewUser(true));
      setShowCookiePopup(false);
    }
  }, [isANewUser]);

  return (
    <div>
      {/*Мета-данные, которые используются везде, кроме страницы Квартиры*/}
      {!pathname.includes('/visual/') && (pathname.match(/[/]/g) || []).length <= 3 && <HelmetSample />}
      <Helmet>
        <link rel='canonical' href={`${LINKS.PORTLAND}${pathname}`} />
      </Helmet>
      {!isLoading && <ScrollToTop />}
      {isLandscape && isMobileOnly && <RotateCaution />}
      <Suspense fallback={<MainLoader />}>
        <Routes>
          {NEW_MAIN_PAGE && <Route path={`${ROUTES.newMainPage}`} element={<MainPage isLoading={isLoading} />} />}
          <Route path='/' element={<MainPage isLoading={isLoading} />} />
          <Route path={`${ROUTES.privacy}/*`} element={<PolicyPage />} />
          <Route path={`${ROUTES.flats.list}/*`} element={<CatalogPage />} />
          <Route path={ROUTES.flats.visual.root} element={<VisualPage isLoading={isLoading} />} />
          <Route path={ROUTES.flats.visual.house} element={<HousePage isLoading={isLoading} />} />
          <Route path={ROUTES.flats.visual.floor} element={<FloorPage />} />
          <Route path={ROUTES.flats.visual.apartment} element={<FlatPage />} />
          <Route path={ROUTES.gallery} element={<GalleryPage isLoading={isLoading} />} />
          <Route path={ROUTES.contacts} element={<ContactsPage isLoading={isLoading} />} />
          <Route path={ROUTES.about} element={<AboutPage isLoading={isLoading} />} />
          <Route path={ROUTES.seafront} element={<SeafrontPage isLoading={isLoading} />} />
          <Route path={ROUTES.news} element={<NewsPage isLoading={isLoading} />} />
          <Route path={ROUTES.onenews} element={<NewsOnePage />} />
          <Route path={ROUTES.favorites.root} element={<FavoritesPage isLoading={isLoading} />} />
          <Route path={ROUTES.documents} element={<DocumentsPage isLoading={isLoading} />} />
          <Route path={ROUTES.commercial} element={<CommercialPage isLoading={isLoading} />} />
          <Route path={ROUTES.architecture} element={<ArchitecturePage isLoading={isLoading} />} />
          <Route path={ROUTES.purchaseTerms.root} element={<TermsPage isLoading={isLoading} />} />
          <Route path={ROUTES.infrastructure} element={<InfrastructurePage isLoading={isLoading} />} />
          <Route path={ROUTES.landscaping} element={<LandscapingPage isLoading={isLoading} />} />
          <Route path={ROUTES.team} element={<TeamPage isLoading={isLoading} />} />
          <Route path={ROUTES.progress} element={<ProgressPage isLoading={isLoading} />} />
          <Route path={ROUTES.oneprogress} element={<ProgressOnePage />} />
          {SITEMAP_FEATURE_ENABLED && <Route path={ROUTES.sitemap} element={<SiteMapPage />} />}
          <Route path={ROUTES.cashbackConditions} element={<CashbackConditionsPage />} />
          <Route path={ROUTES.cashback} element={<CashbackPage />} />
          <Route path={ROUTES.booking} element={<BookingPage />} />
          {PARKING_FEATURE_ENABLED && <Route path={ROUTES.parking} element={<ParkingPage />} />}
          <Route path={ROUTES.serverError} element={<ServerErrorPage />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Suspense>
      {showCookiePopup && <CookiePopup isLoading={isLoading} />}
      <BurgerMenu />
      <ModalForm />
      {isLoading && <GallerySlider />}
    </div>
  );
}

export default App;
